import { supabase } from '~/lib/supabase'

export const fetcher = async (url: string) => {
  const { data, error } = await supabase.auth.getSession()
  if (error || !data.session) {
    throw new Error('Unauthorized')
  }

  const response = await fetch(`${import.meta.env.VITE_API_URL}${url}`, {
    headers: {
      Authorization: `Bearer ${data.session.access_token}`,
    },
  })

  if (!response.ok) {
    const error = await response.text()
    throw new Error(error)
  }

  return response.json()
}
